<template>
  <MainLayout>
    <div class="main">
      <component
        :is="currentComponent"
      />
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '../layouts/MainLayout.vue'
import { MAIN_ROUTES } from '../router/constants';
import Greeting from '../organisms/main/Greeting.vue'
import Registration from '../organisms/main/Registration.vue'
import Instructions from '../organisms/main/Instructions.vue'
import SoundInstruction from '../organisms/main/SoundInstruction.vue'
import AudioProblem from '../organisms/main/AudioProblem.vue'

export default {
  name: 'Main',
  data() {
    return {
      currentComponent: null,
    };
  },
  components: {
    MainLayout,
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.checkActualDashComponent();
      },
    },
  },
  methods: {
    checkActualDashComponent() {
      const { path } = this.$route;
      if (path === MAIN_ROUTES.GREETING) { 
        this.currentComponent = Greeting;
      }
      if (path === MAIN_ROUTES.REGISTRATION) {
        this.currentComponent = Registration;
      }
      if (path === MAIN_ROUTES.INSTRUCTIONS) {
        this.currentComponent = Instructions;
      }
      if (path === MAIN_ROUTES.SOUNDINSTRUCTION) {
        this.currentComponent = SoundInstruction;
      }
      if (path === MAIN_ROUTES.AUDIOPROBLEM) {
        this.currentComponent = AudioProblem;
      }
    }
  }
}
</script>

<style>
  .main {
    min-height: calc(100vh - 80px);
  }
</style>