<template>
  <router-link :to="to"><slot /></router-link>
</template>

<script>
export default {
  name: 'LinkUnderlined',
  props: {
    to: {
      type: String,
      default: '/',
    },
  },
};
</script>

<style lang="scss" scoped>
  a {
    @include link;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }
</style>
