<template>
  <div class="instructions">
    <h1 class="instructions__title">
      <!--Инструкция-->
      {{ $t('instructions.instructions__title') }}
    </h1>
    <Instruction />
    <div @click="handleClick()">
      <ButtonMain class="instructions__button">
        <!--Далее-->
        {{ $t('instructions.instructions__button') }}
      </ButtonMain>
    </div>
  </div>
</template>

<script>
import ButtonMain from '../../atoms/ButtonMain.vue';
import Instruction from '../../molecules/Instruction.vue'

export default {
  name: 'Instructions',
  components: {
     ButtonMain,
     Instruction
  },
  methods: {
    handleClick() {
      this.$router.push('/consent');
    },
  },
}
</script>

<style lang="scss" scoped>
  .instructions {

    &__button {
      width: 250px;
      height: 48px;
      margin: 50px 0 30px 0;
    }

    &__title {
      @include h1;
      padding: 40px 0 25px 0;
    }
  }
</style>