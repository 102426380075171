<template>
  <input type="password" :id="id"  v-model="setValue[id]" :placeholder="placeholder" class="input-password"/>
</template>

<script>
export default {
  name: 'InputPassword',
  props: {
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .input-password {
    border: 1px solid $hintTextColor;
    box-sizing: border-box;
    border-radius: 50px;
    width: 100%;
    height: 45px;
    padding: 10px 10px 10px 18px;
    font-size: 16px;

    &::placeholder {
      color: $hintTextColor;
    }
  }
  .input-password:focus {
    outline: 0;
  }
</style>
