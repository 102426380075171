<template>
  <div class="mock-test-sound">
    <h1 class="mock-test-sound__title"><!--Давайте проверим на пробном вопросе-->{{ $t('mockTestSound.mock-test-sound__title') }}</h1>
    <h2 class="mock-test-sound__title-sound"><!--Сейчас Вы получите задание, в точности такое, как в тесте. Нажмите далее, чтобы перейти к заданию.-->{{ $t('mockTestSound.mock-test-sound__title-sound') }}</h2>
    <audio v-if="this.lang == 'ru'" id="player" class="audio" controls src="../../assets/audio/test-ru.mp3"></audio>
    <audio v-if="this.lang == 'en'" id="player" class="audio" controls src="../../assets/audio/test-en.mp3"></audio>
    <audio v-if="this.lang == 'he'" id="player" class="audio" controls src="../../assets/audio/test-he.mp3"></audio>
    <audio v-if="this.lang == 'uk'" id="player" class="audio" controls src="../../assets/audio/test-ua.mp3"></audio>
    <audio v-if="this.lang == 'de'" id="player" class="audio" controls src="../../assets/audio/test-de.mp3"></audio>
    <audio v-if="this.lang == 'pl'" id="player" class="audio" controls src="../../assets/audio/test-pl.mp3"></audio>
    <template v-if="svgVisibility">
      <Audio
        onclick="document.getElementById('player').play()"
        @click="soundQuestion"
        class="mock-test-sound__audio-svg"
      />
    </template>
    <template v-if="gifVisibility">
      <img
        class="mock-test-sound__gif"
        src="../../assets/images/sound.gif"
        alt="gif"
      />
    </template>
  </div>
</template>

<script>
import Audio from "../../assets/images/audio/audio.svg";

export default {
  name: "MockTestSound",
  data() {
    return {
      lang: null
    }
  },
  components: {
    Audio,
  },
  mounted() {
    this.lang = localStorage.getItem('langPsylab').replace(/"/g, '');
  },
  props: {
    soundQuestion: {
      type: Function,
      default() {
        return {};
      },
    },
    gifVisibility: {
      type: Boolean,
      defoult: false,
    },
    svgVisibility: {
      type: Boolean,
      defoult: false,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.mock-test-sound {
  &__title {
    padding: 100px 0 10px 0;
  }

  &__audio-svg {
    padding: 25px 0 0 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__gif {
    margin: 25px 0 0 0;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: relative;
  }

  &__title-sound {
    width: 700px;
    font-size: 14px;
    font-weight: normal;
    margin: 20px auto;
    
  }
}
.audio {
  display: none;
}
</style>