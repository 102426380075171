<template>
  <p class="error-message"><slot /></p>
</template>

<script>
export default {
  name: 'MessageError',
};
</script>

<style lang="scss" scoped>
  .error-message {
    position: relative;
    font-size: 12px;
    width: 100%;
    color: #FF3333;
    max-width: 200px;
  }
</style>
