<template>
  <v-select
    :type="type"
    :id="id"
    :placeholder="placeholder"
    :options="options"
    v-model="setValue[id]"
    :no-drop="noDrop"
    :disabled="disabled"
    class="input-select"
  />
</template>
<script>
export default {
  name: 'InputSelect',
  props: {
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      noDrop: true,
    };
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (this.options && this.options.length) {
          this.noDrop = false;
          return;
        }
        this.noDrop = true;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
  .input-select {
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 45px;
    padding: 10px 0 0 0;
    font-size: 16px;
  }
  ::v-deep(.vs__clear) {
    display: none;
  }
</style>
