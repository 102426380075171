<template>
  <div class="mock-test">
    <template v-if="question">
      <MockTestSound
        :sound-question="soundQuestion"
        :gif-visibility="this.gifVisibilityNew"
        :svg-visibility="this.svgVisibilityNew"
      />
    </template>
    <template v-if="answer">
      <h1 class="mock-test__title"><!--Ваш ответ--> {{ $t('mockTest.mock-test__title') }}</h1>
      <form @submit.prevent="handleSubmit">
        <div class="mock-test__container">
          <div class="mock-test__section">
            <div class="mock-test__group">
              <h4 class="mock-test__title-group"><!--Мой позывной--> {{ $t('mockTest.mock-test__title-group-MY') }}</h4>
              <div class="mock-test__checkbox-group">
                <div
                  v-for="(item, index) in /*this.testData.callsign*/ callsign"
                  :key="item.id"
                >
                  <input
                    type="radio"
                    name="mockQuestion"
                    class="custom-checkbox"
                    :id="index"
                    :value="item"
                    v-model="answerDataСallsign"
                  />
                  <label :for="index">{{ item }}</label>
                </div>
              </div>
            </div>
            <div class="mock-test__group">
              <h4 class="mock-test__title-group"><!--Позывной друга--> {{ $t('mockTest.mock-test__title-group-FRIEND') }}</h4>
              <div class="mock-test__checkbox-group">
                <div
                  v-for="(item, index) in callsignNew"
                  :key="item.id"
                >
                  <input
                    type="radio"
                    name="mockQuestionNext"
                    class="custom-checkbox"
                    :id="index + 10"
                    :value="item + 10"
                    v-model="answerDataСallsignNew"
                  />
                  <label :for="index + 10">{{ item }}</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3 class="mock-test__title-img-group">
              <!--Укажите мышкой в матрице запрошенные фигуры в установленном порядке-->
              {{ $t('mockTest.mock-test__title-img-group') }}
            </h3>
            <div class="mock-test__img-group">
              <div
                v-for="(fig, index) in this.testData.figure"
                :key="fig.id"
              >
                <input
                  type="checkbox"
                  :id="index + 100"
                  class="checkbox-img"
                  :value="fig.code"
                  v-model="answerDataFigure"
                  v-on:click="limitCheckedFigures($event)"
                />

                <label :for="index + 100">
                  <img
                    :src="fig.red"
                    alt="question"
                    class="mock-test__img"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div>
        <div @click="handleClick()">
          <ButtonMain class="mock-test__button"> <!--Подтвердить ответ--> {{ $t('mockTest.mock-test__button') }} </ButtonMain>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ButtonMain from "../../atoms/ButtonMain.vue";
import MockTestSound from "./MockTestSound.vue";

export default {
  name: "MockTest",
  components: {
    ButtonMain,
    MockTestSound,
  },
  data() {
    return {
      gifVisibilityNew: false,
      svgVisibilityNew: true,
      answerDataСallsign: '',
      answerDataСallsignNew: '',
      answerDataFigure: [],
      answer: false,
      question: true,
      testData: {
        figure: [
          {
            red: require('../../assets/mock-test-image/LBC.png'),
            code: 'LBC'
          },
          {
            red: require('../../assets/mock-test-image/LBR.png'),
            code: 'LBR'
          },
          {
            red: require('../../assets/mock-test-image/LBS.png'),
            code: 'LBS'
          },
          {
            red: require('../../assets/mock-test-image/LBT.png'),
            code: 'LBT'
          },
          {
            red: require('../../assets/mock-test-image/LGC.png'),
            code: 'LGC'
          },
          {
            red: require('../../assets/mock-test-image/LGR.png'),
            code: 'LGR'
          },
          {
            red: require('../../assets/mock-test-image/LGS.png'),
            code: 'LGS'
          },
          {
            red: require('../../assets/mock-test-image/LGT.png'),
            code: 'LGT'
          },
          {
            red: require('../../assets/mock-test-image/LKC.png'),
            code: 'LKC'
          },
          {
            red: require('../../assets/mock-test-image/LKR.png'),
            code: 'LKR'
          },
          {
            red: require('../../assets/mock-test-image/LKS.png'),
            code: 'LKS'
          },
          {
            red: require('../../assets/mock-test-image/LKT.png'),
            code: 'LKT'
          },
          {
            red: require('../../assets/mock-test-image/LRC.png'),
            code: 'LRC'
          },
          {
            red: require('../../assets/mock-test-image/LRR.png'),
            code: 'LRR'
          },
          {
            red: require('../../assets/mock-test-image/LRS.png'),
            code: 'LRS'
          },
          {
            red: require('../../assets/mock-test-image/LRT.png'),
            code: 'LRT'
          },
          {
            red: require('../../assets/mock-test-image/LYC.png'),
            code: 'LYC'
          },
          {
            red: require('../../assets/mock-test-image/LYR.png'),
            code: 'LYR'
          },
          {
            red: require('../../assets/mock-test-image/LYS.png'),
            code: 'LYS'
          },
          {
            red: require('../../assets/mock-test-image/LYT.png'),
            code: 'LYT'
          },
          {
            red: require('../../assets/mock-test-image/SBC.png'),
            code: 'SBC'
          },
          {
            red: require('../../assets/mock-test-image/SBR.png'),
            code: 'SBR'
          },
          {
            red: require('../../assets/mock-test-image/SBS.png'),
            code: 'SBS'
          },
          {
            red: require('../../assets/mock-test-image/SBT.png'),
            code: 'SBT'
          },
          {
            red: require('../../assets/mock-test-image/SGC.png'),
            code: 'SGC'
          },
          {
            red: require('../../assets/mock-test-image/SGR.png'),
            code: 'SGR'
          },
          {
            red: require('../../assets/mock-test-image/SGS.png'),
            code: 'SGS'
          },
          {
            red: require('../../assets/mock-test-image/SGT.png'),
            code: 'SGT'
          },
          {
            red: require('../../assets/mock-test-image/SKC.png'),
            code: 'SKC'
          },
          {
            red: require('../../assets/mock-test-image/SKR.png'),
            code: 'SKR'
          },
          {
            red: require('../../assets/mock-test-image/SKS.png'),
            code: 'SKS'
          },
          {
            red: require('../../assets/mock-test-image/SKT.png'),
            code: 'SKT'
          },
          {
            red: require('../../assets/mock-test-image/SRC.png'),
            code: 'SRC'
          },
          {
            red: require('../../assets/mock-test-image/SRR.png'),
            code: 'SRR'
          },
          {
            red: require('../../assets/mock-test-image/SRS.png'),
            code: 'SRS'
          },
          {
            red: require('../../assets/mock-test-image/SRT.png'),
            code: 'SRT'
          },
        ],
      },
    };
  },
  computed: {
    callsign: function() {
      return [
          this.$t('tqCallsign.first'),
          this.$t('tqCallsign.second'),
          this.$t('tqCallsign.third'),
          this.$t('tqCallsign.fourth'),
          this.$t('tqCallsign.fifth'),
          this.$t('tqCallsign.sixth'),
        ]
    },
    callsignNew: function() {
      return [
          this.$t('tqCallsign.seventh'),
          this.$t('tqCallsign.eighth'),
          this.$t('tqCallsign.ninth'),
          this.$t('tqCallsign.tenth'),
          this.$t('tqCallsign.eleventh'),
          this.$t('tqCallsign.twelfth'),
        ]
    }
  },
  methods: {
    handleClick() {
      const isValuesExist = this.answerDataFigure.includes("LRS") && this.answerDataFigure.includes("SBT");
      if(isValuesExist && this.answerDataСallsign === this.$t('tqCallsign.second') && this.answerDataСallsignNew == this.$t('tqCallsign.eleventh') + 10) {
        this.$router.push("/mock-test/finish-mock-test?answer=true");
      } else this.$router.push("/mock-test/finish-mock-test?answer=false");
    },
    soundQuestion() {
      this.gifVisibilityNew = true;
      this.svgVisibilityNew = false;
      let player = document.getElementById("player");
      var self = this;
      setTimeout(function () {
        self.answer = true;
        self.question = false;
      }, player.duration * 1000);
    },
    limitCheckedFigures(event) {
      const maxChecked = 2;
      const checkedFigures = this.answerDataFigure.filter(Boolean);
      if (checkedFigures.length >= maxChecked && event.target.checked) {
        event.preventDefault();
        event.target.checked = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mock-test {
  min-height: calc(100vh - 80px);
  &__title {
    @include h1;
    padding: 5px 0 5px 0;
  }

  &__container {
    display: flex;
    justify-content: space-around;
  }

  &__title-group {
    padding-bottom: 10px;
    text-align: left;
    margin-top: 10px;
  }

  &__title-img-group {
    font-size: $baseFontsSize;
    font-weight: normal;
    padding-bottom: 15px;
    width: 340px;
    text-align: left;
  }

  &__checkbox-group {
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;

    div {
      margin: 0 50px 15px 0;

      label {
        width: 110px;
      }
    }
  }

  &__button {
    width: 250px;
    height: 48px;
    margin-top: 10px;
  }

  &__img {
    position: relative;
    z-index: 2;
  }

  &__img-group {
    display: flex;
    flex-wrap: wrap;
    width: 340px;

    div {
      width: 50px;
      height: 50px;
      margin: 0 2px 2px 0;
      background: $mainTestColor;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
}
</style>