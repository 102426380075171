export const MAIN_ROUTES = {
  GREETING: '/main/greeting',
  REGISTRATION: '/main/registration',
  INSTRUCTIONS: '/main/instructions',
  SOUNDINSTRUCTION: '/main/sound-instruction',
  AUDIOPROBLEM: '/main/audio-problem'
};

export const TEST_ROUTES = {
  MOCKTESTSOUND: '/mock-test/mock-test-sound',
  MOCKTEST: '/mock-test/mock-test',
  FINISHMOCKTEST: '/mock-test/finish-mock-test',
  TESTSOUND: '/test/test-sound',
  TEST: '/test/test',
  TESTFINISH: '/test/test-finish',
};
