<template>
  <div class="registration">
    <h1 class="registration__title">{{ $t('registration.registration__title') }}</h1>
    <RegistrationForm
      :form-data="formData"
      :name-value="nameValue"
      :surname-data="surnameData"
      :patronymic-data="patronymicData"
      :gender-old-data.sync="genderOldData"
      :day-data="dayData"
      :manth-data="manthData"
      :year-data="yearData"
      @update:genderOldData="updateGenderDataInParent"
    />
    <MessageError
      v-for="message in this.errors?.name"
      :key="message"
      class="login-form__error"
    >
      {{message}}
    </MessageError>
    <MessageError
      v-for="message in this.errors?.patronymic"
      :key="message"
      class="login-form__error"
    >
      {{message}}
    </MessageError>
    <MessageError
      v-for="message in this.errors?.surname"
      :key="message"
      class="login-form__error"
    >
      {{message}}
    </MessageError>
    <MessageError
      v-for="message in this.errors?.gender"
      :key="message"
      class="login-form__error"
    >
      {{message}}
    </MessageError>
    <MessageError
      v-for="message in this.errors?.birthday"
      :key="message"
      class="login-form__error"
    >
      {{message}}
    </MessageError>
    <div
      @click="handleClick()"
      class="registration__button-container"
    >
      <ButtonMain class="registration__button">{{ $t('registration.registration__button') }}</ButtonMain>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ButtonMain from "../../atoms/ButtonMain.vue";
import RegistrationForm from "../../forms/RegistrationForm.vue";
import { getRespondentInfo, respondentUpdate } from "../../api/supportApi";
import MessageError from '../../atoms/MessageError.vue';

export default {
  name: "Registration",
  components: {
    ButtonMain,
    RegistrationForm,
    MessageError
  },
  data() {
    return {
      nameValue: {},
      patronymicData: {},
      formData: {},
      surnameData: {},
      genderOldData: 0,
      dayData: {},
      manthData: {},
      yearData: {},
      errors: null
    };
  },
  mounted() {
    if (!localStorage.getItem('isReloaded')) {
      localStorage.setItem('isReloaded', true);
      location.reload();
    }
    this.loadRespondentInfo();
  },
  methods: {
    updateGenderDataInParent(newValue) {
      this.genderOldData = newValue;
    },
    updateDayDataInParent(newValue) {
      this.dayData = newValue;
    },
    updateManthDataInParent(newValue) {
      this.manthData = newValue;
    },
    updateYearDataInParent(newValue) {
      this.yearData = newValue;
    },
    loadRespondentInfo() {
      getRespondentInfo()
        .then((res) => {
          this.formData = res.data;
          const { respondent } = this.formData;
          this.genderData = respondent.gender
        })
        .catch((error) => {
          const { data } = error.response;
          this.error = data;
        });
    },
    handleClick() {
      this.handleSubmit()
      localStorage.removeItem('isReloaded');
    },
    async handleSubmit() {
      const { respondent } = this.formData;
      let name = respondent.name;
      if (this.nameValue != null) {
        for (var key in this.nameValue) {
        name = this.nameValue[key];
      }
    }
    let surname = respondent.surname;
    if (this.surnameData != null) {
    for (var key in this.surnameData) {
      surname = this.surnameData[key];
    }
  }
  let patronymic = respondent.patronymic;
  if (this.patronymicData != null) {
    for (var key in this.patronymicData) {
      patronymic = this.patronymicData[key];
    }
  }
  let gender = null;
  if (respondent.gender !== null) {
    gender = respondent.gender
  }
  if (this.genderOldData !== null) {
    gender = this.genderOldData
  }
  let birthday = respondent.birthday;
  let day = respondent.birthday.substring(8, 10);
  let manth = respondent.birthday.substring(5, 7);
  let year = respondent.birthday.substring(0, 4);
  for (var key in this.dayData) {
    this.dayData = this.dayData[key];
    if (day !== this.dayData) {
      day = this.dayData
    }
  }
  for (var key in this.manthData) {
    this.manthData = this.manthData[key];
    if (manth !== this.manthData) {
      manth = this.manthData
    }
  }
  for (var key in this.yearData) {
    this.yearData = this.yearData[key];
    if (year !== this.yearData) {
      year = this.yearData
    }
  }
  let newDay = day
  if (newDay < 10 && day != '01' && day != '02' && day != '03' && day != '04' && day != '05' && day != '06' && day != '07' && day != '08' && day != '09') {
    newDay = '0' + day
  }
  birthday = year + '-' + manth + '-' + newDay

  try {
    await respondentUpdate({
      name: name,
      surname: surname,
      patronymic: patronymic,
      gender: gender,
      birthday: birthday,
    });
    this.$router.push("/main/instructions");
  } catch (error) {
    this.errors = error.response.data
  }
}
  },
};
</script>

<style lang="scss" scoped>
.registration {
  min-height: calc(85vh - 50px);
  padding-bottom: 5%;
  &__button-container {
    width: 250px;
    height: 48px;
    margin: 100px auto 30px auto;
  }

  &__button {
    font-size: $h2FontsSize;
  }

  &__title {
    padding: 40px 0 20px 0;
  }
}
.error-message {
  margin: auto;
  padding-top: 5px;
}
</style>