<template>
  <div class="footer">
    <div class="footer__item">
      © Psylab LLC {{ this.currentYear }}
    </div>
    <a href="/" target="blank" class="footer__item">
      <!-- Политика Конфиденциальности -->
      {{ $t('footer.privacy__policy') }}
    </a>
    <a href="/" target="blank"  class="footer__item">
      <!-- Правила пользования сайтом -->
      {{ $t('footer.terms') }}
    </a>
    <div class="footer__item">
      <!-- Сайт разработан и поддерживается Unique Technologies -->
      {{ $t('footer.developed') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    background: $mainColor;
    height: 50px;
    width: 90%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;

    &__item {
      font-size: 13px;
      color: #4F4F4F;
      text-decoration: none;
    }
  }
</style>