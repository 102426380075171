import en from './en.json';
import ru from './ru.json';
import uk from './uk.json';
import he from './he.json';
import de from './de.json';
import pl from './pl.json';

export const defaultLocale = 'en';

export const languages = {
    en,
    ru,
    uk,
    he,
    de,
    pl
}