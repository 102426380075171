<template>
  <div class="card-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardContainer',
};
</script>

<style lang="scss" scoped>
.card-container {
  background: $mainSecondColor;
  border: 2px solid rgba(42, 49, 51, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  margin-bottom: 50px;
}
</style>
