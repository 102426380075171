<template>
  <div class="auth">
    <div class="auth__background">
      <div class="auth__login-form">
        <h1>
          <!-- Добро пожаловать на платформу PsyLab! -->
          {{ $t('auth.welcome') }}
        </h1>
        <h2>
          <!-- Для прохождения теста, введите логин и пароль: -->
          {{ $t('auth.to_pass_the_test') }}
        </h2>
        <LoginForm :on-submit="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "../forms/LoginForm.vue";

export default {
  name: 'Auth',
  data() {
    return {}
  },
  components: {
    LoginForm,
  },
  methods: {
    onSubmit(data) {
      this.$store.dispatch("authStore/logInUser", data)
    }
  }
}
</script>

<style lang="scss" scoped>
.auth {
  min-height: calc(95vh - 50px);
  &__background {
    background: url(../assets/images/main-background.png);
    background-size: cover;
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 0 20%;
  }

  &__text {
    padding: 45px 5% 105px 5%;
    text-align: left;
  }

  &__login-form {
    padding-top: 150px;
    h1 {
      font-weight: 700;
      font-size: 36px;
      color: #ffffff;
    }
    h2 {
      font-size: 20px;
      padding: 10px 0 30px 0;
      color: #FFFFFF;
    }
  }
}
@media only screen and (min-width:1921px) {
  .auth {
    &__login-form {
      padding-top: 350px;
    }
  }
}
</style>