<template>
  <div class="form-input">
    <label :for="id">{{ label }}</label>
    <InputSelect
      :id="id"
      :set-value="setValue"
      :type="type"
      :placeholder="placeholder"
      :options="options"
      :disabled="disabled"
    />
    <MessageError>{{error.join(' ')}}</MessageError>
  </div>
</template>
<script>
import InputSelect from '../atoms/InputSelect.vue';
import MessageError from '../atoms/MessageError.vue';

export default {
  name: 'InputSelectWithLabel',
  components: {
    InputSelect,
    MessageError,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    error: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
  .form-input {
    width: 100%;
    padding: 8px 0;
  }
</style>
