import keysToCamelCase from 'camelcase-keys';
import keysToSnakeCase from 'snakecase-keys';
import * as auth from '../helpers/auth';

import { api, apiPublic } from './index';

async function onRequestInterceptor(
  request,
) {
  const accessToken = auth.getAccessToken();

  request.headers.Authorization = `Bearer ${accessToken}`;

  return request;
}

function onRequestTransformParams(
  request,
) {
  if (/(http|https)/.test(request.url)) {
    // eslint-disable-next-line prefer-destructuring
    request.url = request.url.split(request.baseURL)[1];
  }

  if (request.data) {
    request.data = keysToSnakeCase(request.data, { deep: true });
  }

  return request;
}

function onPrivateRequestErrorInterceptor(
  error,
) {
  auth.logOutWithHistory();

  return Promise.reject(error);
}

export function onResponseInterceptor(
  response,
) {
  if (response.data) {
    response.data = keysToCamelCase(response.data, { deep: true });
  }

  return response;
}

export async function onResponseErrorInterceptor(
  error,
) {
  if (error.response && error.response.data) {
    error.response.data = keysToCamelCase(
      error.response.data,
      { deep: true },
    );
  }

  if (error.response.status >= 500) {
    // TODO 500 error handler
  }

  if (error.response.status === 401) {
    auth.logOutWithHistory();
  }

  return Promise.reject(error);
}

api.interceptors.request.use(
  onRequestTransformParams,
  onPrivateRequestErrorInterceptor,
);

api.interceptors.request.use(onRequestInterceptor);

api.interceptors.response.use(
  onResponseInterceptor,
  onResponseErrorInterceptor,
);

apiPublic.interceptors.request.use(
  onRequestTransformParams,
);

apiPublic.interceptors.response.use(
  onResponseInterceptor,
  onResponseErrorInterceptor,
);
