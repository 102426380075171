import axios from 'axios';

export const axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Cache-Control': 'no-store',
  },
  baseURL: 'https://api.psylab.test.ut.in.ua/api/',
};

export const api = axios.create(axiosConfig);

export const apiPublic = axios.create(
  axiosConfig,
);
