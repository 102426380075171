<template>
  <div class="finish-mock-test">
    <div v-if="this.answer === 'true'">
      <h1 class="finish-mock-test__title">
        <!--Отлично!-->
        {{ $t('finishMockTest.finish-mock-test__title') }}
      </h1>
      <p class="finish-mock-test__text">
        <!--
      Тест длится около 20 минут и у Вас не будет возможности прерваться.
      Поэтому, если у Вас есть вопросы к администратору, или Вам нужно попить
      воды или выйти, сделайте это сейчас.
      Если Вы готовы, нажмите кнопку «Начать тест»
      -->
        {{ $t('finishMockTest.finish-mock-test__text') }}
      </p>
      <div @click="handleClick()">
        <ButtonMain class="finish-mock-test__button">
          <!--Начать тест-->
          {{ $t('finishMockTest.finish-mock-test__button') }}
        </ButtonMain>
      </div>
    </div>
    <div v-if="this.answer === 'false'">
      <p class="finish-mock-test__text-wrong-answer">
        <!--
      Вы ответили не совсем точно, будьте внимательны. Теперь приступим к тестированию.
      Если Вы готовы, нажмите кнопку «Начать тест»
      -->
        {{ $t('finishMockTest.finish-mock-test__text-wrong-answer') }}
      </p>
      <div @click="handleClick()">
        <ButtonMain class="finish-mock-test__button">
          <!--Начать тест-->
          {{ $t('finishMockTest.finish-mock-test__button') }}
        </ButtonMain>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonMain from '../../atoms/ButtonMain.vue';

export default {
  name: 'FinishMockTest',
  data() {
    return {
      answer: null,
    }
  },
  components: {
    ButtonMain
  },
  mounted () {
    this.answer = this.$route.query.answer
  },
  methods: {
    handleClick() {
      this.$router.push('/test/test');
    },
  },
}
</script>

<style lang="scss" scoped>
.finish-mock-test {
  min-height: calc(100vh - 80px);
  &__title {
    @include h1;
    padding: 150px 0 20px 0;
  }

  &__text-wrong-answer {
    padding: 150px 0 0 0;
    font-size: 14px;
    width: 400px;
    margin: auto;
  }

  &__button {
    width: 300px;
    height: 56px;
    margin: 35px 0 100px 0;
  }

  &__text {
    font-size: 14px;
    width: 700px;
    margin: auto;
  }
}
</style>