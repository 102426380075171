import Vue from 'vue';
import Vuex from 'vuex';
import authStore from './authStore';
import errorHandlerStore from './errorHandlerStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authStore,
    errorHandlerStore,
  },
});
