import Vue from 'vue';
import Embed from 'v-video-embed';
import vSelect from 'vue-select';
import vClickOutside from 'v-click-outside';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vue-select/dist/vue-select.css';
import './styles/main.scss';
import './api/interceptors';
import * as Tabs from 'vue-slim-tabs';

//!
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import {languages} from './i18n';
// import {defaultLocale} from './i18n';

const messages = Object.assign(languages);

let lang = 'en';
if (localStorage.getItem('langPsylab')) {
  lang = localStorage.getItem('langPsylab')
}

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages,
});

const updateLang = (lang) => {
  localStorage.setItem('langPsylab', lang);
  i18n.locale = lang;
};

Vue.use(vClickOutside);
Vue.component('v-select', vSelect);
Vue.use(Embed);
Vue.use(Tabs)

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
  mounted() {
    // при монтировании компонента записываем текущий язык в localStorage
    updateLang(lang);
  },
  watch: {
    // следим за изменением языка
    '$i18n.locale'(newVal) {
      updateLang(newVal);
    },
  },
}).$mount('#app');
