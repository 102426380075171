<template>
  <div class="greeting">
    <h1 class="greeting__title">
      <!--Приветствие -->
      {{ $t('greeting.greeting__title') }}
    </h1>
    <p class="greeting__text">
      <!--
      Сейчас Вам предстоит пройти несколько тестов, которые помогут определить
      Ваши способности в ряде аспектов. Перед каждым тестом Вам будет предоставлена
      инструкция к каждому тесту отдельно. Все тестирование займет около 60 минут.
      Между тестами Вы сможете сделать небольшую паузу, чтобы попить воды и/или
      сходить в туалет. Если у Вас возникнут какие-либо вопросы,
      Вы можете обратиться к администратору.
      -->
      {{ $t('greeting.greeting__text') }}
    </p>
    <div @click="handleClick()">
      <ButtonMain class="greeting__button">
        <!--Начать-->
        {{ $t('greeting.greeting__button') }}
      </ButtonMain>
    </div>
  </div>
</template>

<script>
import ButtonMain from '../../atoms/ButtonMain.vue';

export default {
  name: 'Greeting',
  components: {
    ButtonMain
  },
  methods: {
    handleClick() {
      this.$router.push('/main/registration');
    },
  },
}
</script>

<style lang="scss" scoped>
.greeting {
  padding: 10% 25% 0 25%;
  min-height: calc(75vh - 50px);
  &__title {
    padding-bottom: 20px;
  }

  &__text {
    text-align: center;
    padding-bottom: 40px;
  }

  &__button {
    width: 300px;
    height: 56px;
    font-size: 22px;
  }
}
</style>