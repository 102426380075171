<template>
  <div
    class="instruction"
    :class="{'rtl': isHebrew}"
  >
    <!-- <h2 class="instruction__title-section">
      Рекомендации по прохождению
    </h2> -->
    <p class="instruction__text">
      <!--
      Перед Вами тест, который позволит оценить Вашу память и внимательность.
      Задания теста напоминают работу диспетчера. В каждом задании, в наушники
      в голосовом режиме Вам будет подан запрос от виртуального напарника.
      Вы услышите два позывных: свой и чужой, а также характеристики фигур,
      которые Вам необходимо будет найти на сетке из 36 фигур.
      -->
      {{ $t('instruction.instruction__text-1') }}
    </p>
    <p class="instruction__text">
      <!--
      Ваша задача запомнить свой и чужой позывные и фигуры,
      которые необходимо найти. Далее, Вам будет необходимо
      выбрать свой позывной из четырех вариантов раздела «Свой позывной»,
      чужой позывной из четырех вариантов раздела «Чужой позывной», а также
      фигуры, озвученные в голосовом запросе. 
      Для иллюстрации, посмотрите видео ниже.
      -->
      {{ $t('instruction.instruction__text-2') }}
    </p>
    <!-- <h2 class="instruction__title-section">
      Во время тестирования
    </h2> -->
    <!-- <ul class="instruction__list">
      <li>
        Проверяйте ответы, учитывайте, что вероятность замены
        неверного ответа на верный всегда выше вероятности обратной замены.
      </li>
      <li>
        С осторожностью используйте гадание — сложный вопрос лучше пропустить
        и затем вновь вернуться к нему.
      </li>
      <li>
        Внимательно читайте вопросы, вникая в каждое слово — в них может
        быть «подвох».
      </li>
      <li>
        Не пугайтесь вопросов на вычисление — нанимателям не столько важны навыки
        устного счета, сколько подход к решению.
      </li>
      <li>
        Сначала отвечайте на вопросы, в правильном ответе на которые вы не сомневаетесь.
      </li>
    </ul> -->
    <!-- <div>
      <video id="video" class="instruction__video-container" controls="controls" :poster="require('../assets/video/video.jpg')">
        <source :src="require('../assets/video/x.mp4')" type="video/mp4" >
      </video>
    </div> -->
    <!-- <h2 class="instruction__title-section">
      После выполнения
    </h2>
    <p class="instruction__text">
      Внимательно проверьте заполнение опросника.
      Случается, что вместо правильного ответа,
      соискатели по невнимательности записывают в
      лист ответов неправильные.
    </p> -->
  </div>
</template>

<script>
export default {
  name: 'Instruction',
  data() {
    return {
      isHebrew: false
    };
  },
  mounted() {
    if (localStorage.getItem('langPsylab').replace(/"/g, '') === 'he') {
      this.isHebrew = true
    }
  }
}
</script>

<style lang="scss" scoped>
.instruction {
  padding: 0 5%;
  text-align: left;

  &__list {
    padding: 0 0 0 20px;
    margin: 0;
  }

  &__title-section {
    @include h2-bold;
    padding-bottom: 10px;
  }

  &__text {
    padding-bottom: 20px;
  }

  &__video-container {
    width: 320px;
    height: 200px;
    margin: 25px 0;
  }
}
.rtl {
  direction: rtl;
  text-align: right;
}
</style>