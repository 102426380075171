<template>
  <div class="layout">
    <Header :timer-is-hidden="true" :questions-is-hidden="true" :button-is-hidden="true" :name-is-hidden="true" />
      <slot />
    <Footer />
  </div> 
</template>

<script>
import Header from '../organisms/Header.vue'
import Footer from '../organisms/Footer.vue'
export default {
  name: 'Layout',
  components: {
      Header,
      Footer,
  }
}
</script>

<style lang="scss" scoped>
  .layout {
    position: relative;
    min-height: 100%;
  }
</style>