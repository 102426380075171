<template>
  <button :type="type">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonMain',
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  height: 100%;
  background: $elementsColor;
  font-size: $baseFontsSize;
  border: none;
  border-radius: 100px;
  color: $mainSecondColor;
  font-weight: normal;
  line-height: 22px;
  outline: none;

  &:hover {
    @include mainButtonHover;
  }

  &:active {
    @include mainButtonActive ;
  }
}
</style>
