<template>
  <div class="input-radio">
    <input
      type="radio"
      :id="id"
      :placeholder="placeholder"
      :name="name"
      v-model="setValue[id]"
      :disabled="disabled"
      :checked="checked"
    />
    <label :for="id"></label>
  </div>
</template>
<script>
export default {
  name: "InputRadio",
  props: {
    id: {
      type: String,
      default: "",
    },
    for: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.input-radio {
  display: flex;
  position: relative;
  bottom: 2px;
}
.input-radio label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 18px;
	margin-right: 0;
	line-height: 18px;
	user-select: none;
  border: 1px solid #A6AEB2;
  border-radius: 100px;
}
.input-radio label:before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	position: absolute;
	left: 4px;
  top: 3.5px;
  bottom: 1px;
  border-radius: 100px;
}
.input-radio input[type=radio]:checked + label:before {
  background: $hintTextColor;
}
.input-radio label:hover:before {
	filter: brightness(120%);
}
.input-radio input[type=radio]:disabled + label:before {
	filter: grayscale(100%);
}
input[type="radio"] {
  display: none;
}
</style>
