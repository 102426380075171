<template>
  <div class="login-form">
    <form @submit.prevent="handleSubmit">
      <div class="login-form__inputs-group">
        <InputTextWithLabel
          class="login-form__input"
          :id="'login'"
          :set-value="userInfo"
          :error="error['login']"
          :placeholder="$t('loginForm.login-placeholder')"
        />
        <InputPasswordWithLabel
          class="login-form__input"
          :id="'password'"
          :set-value="userInfo"
          :error="error['password']"
          :placeholder="$t('loginForm.password-placeholder')"
        />
        <MessageError
          v-for="message in error['nonFieldError']"
          :key="message"
          class="login-form__error"
        >
          {{message}}
        </MessageError>
      </div>
      <ButtonMain
        :type="'submit'"
        class="login-form__button-entrance"
      >
        {{ $t('loginForm.logIn') }}
        <!--Войти-->
      </ButtonMain>
    </form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import InputTextWithLabel from '../molecules/InputTextWithLabel.vue';
import InputPasswordWithLabel from '../molecules/InputPasswordWithLabel.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import MessageError from '../atoms/MessageError.vue';
import LinkUnderlined from '../atoms/LinkUnderlined.vue';

export default {
  name: 'LoginForm',
  components: {
    InputTextWithLabel,
    InputPasswordWithLabel,
    ButtonMain,
    MessageError,
    LinkUnderlined,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userInfo: {},
    };
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      this.onSubmit(this.userInfo);
    },
  },
};
</script>
<style lang="scss" scoped>
.login-form {
  display: flex;
  width: 100%;

  &__button-entrance {
    height: 48px;
    width: 420px;
  }

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    position: relative;
  }

  &__inputs-group {
    display: flex;
    justify-content: space-between;
    width: 420px;
    padding-bottom: 15px;
  }

  ::v-deep(input::placeholder) {
    color: #A6AEB2;
  }

  &__input {
    width: 200px;
  }

  &__error {
    position: absolute;
    top: 55px;
    right: 15%;
  }
}
::v-deep(.error-message) {
  text-align: left;
}
</style>
