<template>
  <div class="test-layout">
      <slot />
    <Footer />
  </div> 
</template>

<script>
import Footer from '../organisms/Footer.vue'
export default {
  name: 'TestLayout',
  components: {
      Footer,
  }
}
</script>

<style lang="scss" scoped>
  .test-layout {
    position: relative;
    min-height: 100%;
  }
</style>