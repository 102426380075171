<template>
  <TestLayout>
    <div class="test">
      <component
        :is="currentComponent"
      />
    </div>
  </TestLayout>
</template>

<script>
import TestLayout from '../layouts/TestLayout.vue'
import { TEST_ROUTES } from '../router/constants';
import Test from '../organisms/test/Test.vue'


export default {
  name: 'Test',
  data() {
    return {
      currentComponent: null,
    };
  },
  components: {
    TestLayout,
    Test,
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.checkActualDashComponent();
      },
    },
  },
  methods: {
    checkActualDashComponent() {
      const { path } = this.$route;
      if (path === TEST_ROUTES.TESTSOUND) { 
        this.currentComponent = TestSound;
      }
      if (path === TEST_ROUTES.TEST) { 
        this.currentComponent = Test;
      }
      if (path === TEST_ROUTES.TESTFINISH) { 
        this.currentComponent = TestFinish;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>