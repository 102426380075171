<template>
  <MainLayout>
    <div class="consent">
      <h1>
        {{ $t('consent.consent__consent') }}
      </h1>
      <div class="consent__text">
        {{ $t('consent.consent__text_1') }}
      </div>
      <div
        class="consent__text"
        v-if="$t('consent.consent__text_2') !== 'consent.consent__text_2'"
      >
        {{ $t('consent.consent__text_2') }}
      </div>
      <div
        class="consent__text"
        v-if="$t('consent.consent__text_3') !== 'consent.consent__text_3'"
      >
        {{ $t('consent.consent__text_3') }}
      </div>
      <div
        class="consent__text"
        v-if="$t('consent.consent__text_4') !== 'consent.consent__text_4'"
      >
        {{ $t('consent.consent__text_4') }}
      </div>
      <div
        class="consent__text"
        v-if="$t('consent.consent__text_5') !== 'consent.consent__text_5'"
      >
        {{ $t('consent.consent__text_5') }}
      </div>
      <div
        class="consent__text"
        v-if="$t('consent.consent__text_6') !== 'consent.consent__text_6'"
      >
        {{ $t('consent.consent__text_6') }}
      </div>
      <div
        class="consent__text"
        v-if="$t('consent.consent__text_7') !== 'consent.consent__text_7'"
      >
        {{ $t('consent.consent__text_7') }}
      </div>
      <div
        class="consent__text"
        v-if="$t('consent.consent__text_8') !== 'consent.consent__text_8'"
      >
        {{ $t('consent.consent__text_8') }}
      </div>
      <div class="consent__buttons-group">
        <div
          class="consent__button-cancel"
          @click="handleModalOpen()"
        >{{ $t('consent.consent__refuse') }}</div>
        <div @click="goToInstruction()">
          <ButtonMain class="consent__button">{{ $t('consent.consent__button') }}</ButtonMain>
        </div>
      </div>
    </div>
    <template v-if="modalOpen">
      <ModalContainer :on-close="() => (modalOpen = false)">
        <p class="modal__text">{{ $t('consent.consent__text-abort') }}</p>
        <div class="modal__button-container">
          <div
            class="modal__button"
            @click="handleModalClose()"
          >
            <ButtonMain class="modal__button-button"> <!--Нет-->{{ $t('header.modal__button-button-NO') }} </ButtonMain>
          </div>
          <div
            class="modal__button"
            @click="goHome()"
          >
            <ButtonMain class="modal__button-button"> <!--Да-->{{ $t('header.modal__button-button-YES') }} </ButtonMain>
          </div>
        </div>
      </ModalContainer>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '../layouts/MainLayout.vue'
import ButtonMain from '../atoms/ButtonMain.vue';
import ModalContainer from "../molecules/ModalContainer";

export default {
  name: 'Consent',
  data() {
    return {
      currentComponent: null,
      modalOpen: false
    };
  },
  components: {
    MainLayout,
    ButtonMain,
    ModalContainer
  },
  methods: {
    goToInstruction () {
      this.$router.push('/main/sound-instruction')
    },
    handleModalOpen() {
      this.modalOpen = true;
    },
    handleModalClose() {
      this.modalOpen = false;
    },
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.consent {
  height: calc(100vh - 80px);
  min-height: 750px;

  h1 {
    @include h1;
    padding: 40px 0 25px 0;
  }
  &__button {
    width: 250px;
    height: 48px;
    margin-top: 10px;
    margin-left: 50px;
  }
  &__button-cancel {
    color: #8a9396;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }
  }
  &__buttons-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 5%;
  }
  &__text {
    text-align: left;
    padding: 0 5% 20px 5%;
  }
}
.modal {
  &__title {
    font-size: 24px;
    padding: 70px 0 20px 0;
  }

  &__text {
    font-size: 16px;
    padding: 45px 0 35px 0;
  }

  &__button-container {
    display: flex;
    justify-content: space-around;
    height: 48px;
  }

  &__button {
    font-size: 18px;
    width: 150px;
  }

  &__button-button {
    font-size: 18px;
  }
}
</style>
