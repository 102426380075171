<template>
  <div class="main-layout">
    <Header :login-form-is-hidden="true"  :timer-is-hidden="true" :questions-is-hidden="true" :name-is-hidden="true" :button-is-hidden="true"/>
      <slot />
    <Footer />
  </div> 
</template>

<script>
import Header from '../organisms/Header.vue'
import Footer from '../organisms/Footer.vue'
export default {
  name: 'MainLayout',
  components: {
      Header,
      Footer,
  }
}
</script>

<style lang="scss" scoped>
  .main-layout {
    position: relative;
  }
</style>