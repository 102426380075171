<template>
  <div class="sound-instruction">
    <template v-if="instructionTemplate">
      <h1 class="sound-instruction__title"><!--Инструкция 3-->{{ $t('soundInstruction.sound-instruction__title') }}</h1>
      <p class="sound-instruction__text-bold">
        <!--
        Будьте осторожны, и не помечайте больше позывных/фигур,
        чем столько, сколько было озвучено. В противном случае,
        Ваш ответ не будет засчитан.
        -->
        {{ $t('soundInstruction.sound-instruction__text-bold') }}
      </p>
      <p class="sound-instruction__text">
        <!--
        ВНИМАНИЕ! Важен порядок выбора ответов: сначала нужно отметить
        услышанные Вами позывные, а потом отметить фигуры на сетке.
        -->
        {{ $t('soundInstruction.sound-instruction__text-1') }}
      </p>
      <p class="sound-instruction__text">
        <!--
        Время, отведенное Вам для прохождения теста ограничено.
        Вам нужно пройти как можно больше заданий, тем не менее,
        правильность Ваших ответов учитывается в первую очередь.
         -->
         {{ $t('soundInstruction.sound-instruction__text-2') }}
      </p>
      <h2 class="sound-instruction__title-sound"><!--Проверить звук-->{{ $t('soundInstruction.sound-instruction__title-sound') }}</h2>
      <audio id="player" class="audio" controls src="../../assets/audio/test-new.mp3"></audio>
      <Audio
        v-if="svgVisibility"
        onclick="document.getElementById('player').play()"
        @click="checkSound()"
        class="sound-instruction__audio-svg"
      />
      <img
        v-if="gifVisibility"
        class="sound-instruction__gif"
        src="../../assets/images/sound.gif"
        alt="gif"
      />
    </template>
    <template v-if="audioProblemTemplate">
      <div class="audio-problem">
        <h1 class="audio-problem__title"><!--Обратитесь к администратору-->{{ $t('soundInstruction.audio-problem__title') }}</h1>
        <p class="audio-problem__text">
          <!--
          Главное условие при выполнении любого теста при приеме на работу — не
          пугаться и быть спокойным. Не следует долго задерживаться на вопросе,
          ответ на который вам неизвестен. К нему можно будет вернуться позднее.
          Проверяйте ответы, учитывайте, что вероятность замены неверного ответа
          на верный всегда выше вероятности обратной замены.
          -->
          {{ $t('soundInstruction.audio-problem__text') }}
        </p>
        <h2 class="audio-problem__title-sound"><!--Проверить звук-->{{ $t('soundInstruction.audio-problem__title-sound') }}</h2>
        <audio
          id="player"
          class="audio"
          controls
          src="../../assets/audio/test-new.mp3"
        ></audio>
        <Audio
          v-if="svgVisibility"
          onclick="document.getElementById('player').play()"
          @click="checkSoundProblem()"
          class="audio-problem__audio-svg"
        />
        <img
          v-if="gifVisibility"
          class="sound-instruction__gif"
          src="../../assets/images/sound.gif"
          alt="gif"
        />
      </div>
    </template>
    <template v-if="checkSoundTemplate">
      <div>
        <h3 class="sound-instruction__confirm-title">
          <!--Было ли слышно звук отчетливо?-->
          {{ $t('soundInstruction.sound-instruction__confirm-title') }}
        </h3>
        <div class="sound-instruction__confirm-block">
          <div @click="handleClickNot()">
            <ButtonMain class="sound-instruction__confirm-block-button">
              <!--Нет-->
              {{ $t('soundInstruction.sound-instruction__confirm-block-button-NO') }}
            </ButtonMain>
          </div>
          <div @click="handleClick()">
            <ButtonMain class="sound-instruction__confirm-block-button">
              <!--Да-->
              {{ $t('soundInstruction.sound-instruction__confirm-block-button-YES') }}
            </ButtonMain>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Audio from "../../assets/images/audio/audio.svg";
import ButtonMain from "../../atoms/ButtonMain.vue";

export default {
  name: "SoundInstruction",
  data() {
    return {
      checkSoundTemplate: false,
      svgVisibility: true,
      gifVisibility: false,
      audioProblemTemplate: false,
      instructionTemplate: true,
    };
  },
  components: {
    Audio,
    ButtonMain,
  },
  methods: {
    handleClick() {
      this.$router.push("/mock-test/mock-test");
    },
    handleClickNot() {
      this.instructionTemplate = false;
      this.checkSoundTemplate = false;
      this.audioProblemTemplate = true;
    },
    checkSound() {
      this.svgVisibility = false;
      this.gifVisibility = true;
      let sound = document.getElementById("player");
      var self = this;
      setTimeout(function () {
        self.checkSoundTemplate = true;
        self.spund = false;
        self.gifVisibility = false;
        self.svgVisibility = true;
      }, sound.duration * 1000);
    },
    checkSoundProblem() {
      this.svgVisibility = false;
      this.gifVisibility = true;
      let sound = document.getElementById("player");
      var self = this;
      setTimeout(function () {
        self.checkSoundTemplate = true;
        self.spund = false;
        self.gifVisibility = false;
        self.svgVisibility = true;
      }, sound.duration * 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.sound-instruction {
  padding: 0 12% 0 12%;
  min-height: calc(95vh - 50px);
  &__title {
    @include h1;
    padding: 40px 0 15px 0;
  }

  &__text {
    padding-bottom: 20px;
    width: 700px;
    margin: auto;
  }

  &__text-bold {
    padding: 20px 0;
    font-weight: bold;
    width: 700px;
    margin: auto;
  }

  &__title-sound {
    padding-bottom: 25px;
  }

  &__audio-svg {
    &:hover {
      cursor: pointer;
    }
  }

  &__confirm-block {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
  }

  &__svg-block {
    position: relative;
  }

  &__gif {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: relative;
  }

  &__confirm-block-button {
    height: 48px;
    width: 150px;
    margin: 0 25px;
  }

  &__confirm-title {
    padding: 45px 0 25px 0;
  }
}
.audio-problem {
  &__title {
    @include h1;
    padding: 80px 0 40px 0;
  }

  &__text {
    padding: 0 10% 40px 10%;
  }

  &__title-sound {
    padding-bottom: 20px;
  }

  &__audio-svg {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>