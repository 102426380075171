import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../pages/Login.vue'
import Main from '../pages/Main.vue'
import MockTest from '../pages/MockTest.vue'
import Test from '../pages/Test.vue'
import Consent from '../pages/Consent.vue'

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/main/:id', name: 'Main', component: Main },
  { path: '/consent', name: 'Consent', component: Consent },
  { path: '/mock-test/:id', name: 'MockTest', component: MockTest },
  { path: '/test/:id', name: 'Test', component: Test },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });
export default router;
