<template>
  <MainLayout>
    <div class="mock-test">
      <component
        :is="currentComponent"
      />
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '../layouts/MainLayout.vue'
import { TEST_ROUTES } from '../router/constants';
import MockTestSound from '../organisms/mock-test/MockTestSound.vue'
import MockTest from '../organisms/mock-test/MockTest.vue'
import FinishMockTest from '../organisms/mock-test/FinishMockTest.vue'

export default {
  name: 'MockTest',
  data() {
    return {
      currentComponent: null,
    };
  },
  components: {
    MainLayout,
    MockTestSound,
    MockTest,
    FinishMockTest
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.checkActualDashComponent();
      },
    },
  },
  methods: {
    checkActualDashComponent() {
      const { path } = this.$route;
      if (path === TEST_ROUTES.MOCKTESTSOUND) { 
        this.currentComponent = MockTestSound;
      }
      if (path === TEST_ROUTES.MOCKTEST) {
        this.currentComponent = MockTest;
      }
      if (path === TEST_ROUTES.FINISHMOCKTEST) {
        this.currentComponent = FinishMockTest;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>