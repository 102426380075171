import Vue from 'vue';
import Vuex from 'vuex';

import {
  logInUser,
} from '../api/authApi';
import router from '../router';
import { setAuthData } from '../helpers/auth';
import { MAIN_ROUTES } from '../router/constants';

Vue.use(Vuex);

const state = {
  userInfo: null,
  personalAccounts: [],
  error: {},
  actualAccount: null,
};

const mutations = {
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
};

const getters = {
  checkUserProfileAccount: (state) => {
    const { actualAccount, userInfo } = state;
    return actualAccount?.personalNumber === userInfo?.personalNumber;
  },
};

const actions = {

  logInUser({ commit }, payload) {
  const lang = localStorage.getItem('langPsylab');
  logInUser(payload, lang).then((res) => {
    localStorage.setItem('langPsylab', JSON.stringify(res.data.languageCode).replace(/"/g, ""))
    const { token, tokenTimeExpired, user } = res.data;
    commit('setError', {});
    commit('setUserInfo', user);
    setAuthData(token, tokenTimeExpired, user);
    router.push(MAIN_ROUTES.GREETING).then(() => {
      window.location.reload();
    });
  }).catch((error) => {
    const { response } = error;
    const data = response && response.data ? response.data : null;
    commit('setError', data);
  });
  }
};

const authStore = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default authStore;
