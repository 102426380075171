<template>
  <div class="form-input">
    <label :for="id">{{ label }}</label>
    <InputPassword :id="id" :set-value="setValue" :placeholder="placeholder" />
    <MessageError>{{error.join(' ')}}</MessageError>
  </div>
</template>

<script>
import InputPassword from '../atoms/InputPassword.vue';
import MessageError from '../atoms/MessageError.vue';

export default {
  name: 'InputPasswordWithLabel',
  components: {
    InputPassword,
    MessageError,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-input {
    width: 100%;
    padding: 8px 0;
  }
</style>
