import axios from 'axios';
import { api } from './index';
// eslint-disable-next-line import/no-cycle
import { onResponseInterceptor, onResponseErrorInterceptor } from './interceptors';
// eslint-disable-next-line import/no-cycle
import { getAccessToken } from '../helpers/auth';

export const getRespondentInfo = (params) => api.request({
  method: 'POST',
  url: '/getRespondentInfo',
  params,
});

export const getStart = (params) => api.request({
  method: 'POST',
  url: '/start',
  params,
});

export const nextQuestion = (params) => api.request({
  method: 'POST',
  url: '/nextQuestion',
  params,
});

export const nextSeries = (params) => api.request({
  method: 'POST',
  url: '/nextSeries',
  params,
});

export const renouncement = (params) => api.request({
  method: 'POST',
  url: '/renouncement',
  params,
});

export const respondentUpdate = (params) => api.request({
  method: 'POST',
  url: 'respondentUpdate',
  params,
});
