<template>
  <div class="registration-form">
    <form @submit="handleSubmit">
      <div
        v-for="date in formData"
        :key="date.id"
      >
        <div class="registration-form__container">
          <h2 class="registration-form__title-group">
            <!--Имя-->
            {{ $t('registrationForm.registration-form__title-group-NAME') }}
            <span>*</span>
          </h2>
          <InputTextWithLabel
            class="registration-form__input"
            :set-value="nameValue"
            :placeholder="date.name"
          />
        </div>
        <div class="registration-form__container">
          <h2 class="registration-form__title-group">
            <!--Отчество-->
            {{ $t('registrationForm.registration-form__title-group-PATRONYMIC') }}
          </h2>
          <InputTextWithLabel
            class="registration-form__input"
            :set-value="patronymicData"
            :placeholder="date.patronymic"
          />
        </div>
        <div class="registration-form__container">
          <h2 class="registration-form__title-group">
            <!--Фамилия-->
            {{ $t('registrationForm.registration-form__title-group-SURNAME') }}
            <span>*</span>
          </h2>
          <InputTextWithLabel
            class="registration-form__input"
            :placeholder="date.surname"
            :set-value="surnameData"
          />
        </div>
        <div class="registration-form__container">
          <h2 class="registration-form__title-group registration-form__genus">
            <!--Пол-->
            {{ $t('registrationForm.registration-form__genus') }}
            <span>*</span>
          </h2>
          <div class="registration-form__radio-group">
            <input
              type="radio"
              name="radio"
              id="0"
              v-if="date.gender === 0"
              checked
              for="0"
              class="registration-form__radio-male"
              required
              value="0"
              @change="updateGenderData(0)"
            />
            <input
              type="radio"
              name="radio"
              id="0"
              v-if="date.gender !== 0"
              for="0"
              class="registration-form__radio-male"
              required
              value="0"
              @change="updateGenderData(0)"
            />
            <label
              class="registration-form__radio-label"
              for="0"
            >{{ $t('registrationForm.registration-form__radio-label-Non-binary') }}</label>
            <input
              type="radio"
              name="radio"
              id="2"
              v-if="date.gender === 2"
              checked
              for="2"
              class="registration-form__radio-male"
              required
              value="2"
              @change="updateGenderData(2)"
            />
            <input
              type="radio"
              name="radio"
              id="2"
              v-if="date.gender !== 2"
              for="2"
              class="registration-form__radio-male"
              required
              value="2"
              @change="updateGenderData(2)"
            />
            <label
              class="registration-form__radio-label"
              for="2"
            ><!--Женский-->{{ $t('registrationForm.registration-form__radio-label-WOOMAN') }}</label>
            <input
              type="radio"
              name="radio"
              id="1"
              v-if="date.gender === 1"
              checked
              for="1"
              class="registration-form__radio-male"
              required
              value="1"
              @change="updateGenderData(1)"
            />
            <input
              type="radio"
              name="radio"
              id="1"
              v-if="date.gender !== 1"
              for="1"
              class="registration-form__radio-male"
              required
              value="1"
              @change="updateGenderData(1)"
            />
            <label
              class="registration-form__radio-label"
              for="1"
            ><!--Мужской-->{{ $t('registrationForm.registration-form__radio-label-MAN') }}</label>
          </div>
        </div>
        <div class="registration-form__container">
          <h2 class="registration-form__title-group">
            <!--Дата рождения-->
            {{ $t('registrationForm.registration-form__title-group') }}
            <span>*</span>
          </h2>
          <div
            class="registration-form__container-birthday"
            v-if="date.birthday !== null"
          >
            <InputSelectWithLabel
              class="registration-form__day"
              :label="$t('registrationForm.registration-form__day')"
              :placeholder="date.birthday.slice(8, 10)"
              :options="['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']"
              :set-value="dayData"
            />
            <InputSelectWithLabel
              class="registration-form__manth"
              :label="$t('registrationForm.registration-form__manth')"
              :placeholder="date.birthday.slice(5, 7)"
              :options="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"
              :set-value="manthData"
            />
            <InputSelectWithLabel
              class="registration-form__year"
              :label="$t('registrationForm.registration-form__year')"
              :placeholder="date.birthday.slice(0, 4)"
              :options="['2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984', '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968', '1967', '1966', '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958', '1957', '1956', '1955', '1954', '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946', '1945', '1944', '1943', '1942', '1941', '1940', '1939', '1938', '1937', '1936', '1935', '1934', '1933', '1932', '1931', '1930', '1929', '1928', '1927', '1926', '1925', '1924', '1923', '1922', '1921', '1920' ]"
              :set-value="yearData"
              />
          </div>

          <div
            class="registration-form__container-birthday"
            v-if="date.birthday === null"
          >
            <InputSelectWithLabel
              class="registration-form__day"
              :label="$t('registrationForm.registration-form__day')"
              placeholder="--"
              :options="['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']"
              :set-value="dayData"
            />
            <InputSelectWithLabel
              class="registration-form__manth"
              :label="$t('registrationForm.registration-form__manth')"
              placeholder="--"
              :options="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"
              :set-value="manthData"
              />
            <InputSelectWithLabel
              class="registration-form__year"
              :label="'Год'"
              placeholder="----"
              :options="['2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984', '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968', '1967', '1966', '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958', '1957', '1956', '1955', '1954', '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946', '1945', '1944', '1943', '1942', '1941', '1940', '1939', '1938', '1937', '1936', '1935', '1934', '1933', '1932', '1931', '1930', '1929', '1928', '1927', '1926', '1925', '1924', '1923', '1922', '1921', '1920' ]"
              :set-value="yearData"
              />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputTextWithLabel from '../molecules/InputTextWithLabel.vue';
import InputRadio from '../atoms/InputRadio.vue';
import InputSelectWithLabel from '../molecules/InputSelectWithLabel.vue';
import ButtonMain from '../atoms/ButtonMain.vue';

export default {
  name: 'RegistrationForm',
  components: {
    InputTextWithLabel,
    InputSelectWithLabel,
    InputRadio,
    ButtonMain
  },
  data() {
    return {
    }
  },
  methods: {
    updateGenderData(newValue) {
      this.$emit("update:genderOldData", newValue);
    },
  },
  props: {
    nameValue: {
      type: Object,
      default() {
        return ''
      },
    },
    patronymicData: {
      type: Object,
      default() {
        return ''
      }
    },
    surnameData: {
      type: Object,
      default() {
        return ''
      }
    },
    dayData: {
      type: Object,
      default() {
        return {};
      }
    },
    manthData: {
      type: Object,
      default() {
        return {};
      }
    },
    yearData: {
      type: Object,
      default() {
        return {};
      }
    },
    genderOldData: {
      type: Number,
      default() {
        return 0
      }
    },
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    handleSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  } 
}
</script>

<style lang="scss" scoped>
.registration-form {
  &__container {
    width: 420px;
    margin: auto;
    text-align: left;
  }

  &__input {
    height: 46px;
  }

  &__title-group {
    font-size: $baseFontsSize;
    font-weight: normal;
    color: $mainTextColor;
    padding-top: 15px;
  }

  &__container-birthday {
    display: flex;
  }

  &__radio-group {
    display: flex;
    align-items: center;
    margin: 15px 0 25px 0;

    input {
      position: relative;
      bottom: 1px;
    }
  }

  &__radio-male,
  &__radio-female {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  &__radio-label {
    margin-right: 40px;
    position: relative;
    bottom: 3px;

    &:hover {
      cursor: pointer;
    }
  }

  &__genus {
    padding-top: 25px;
  }

  &__day {
    width: 120px;
    margin-right: 10px;
  }
  &__manth {
    width: 160px;
    margin-right: 10px;
  }
  &__year {
    width: 120px;
    margin-right: 10px;
  }
}

::v-deep(.vs__dropdown-toggle) {
  border-radius: 25px;
  height: 46px;
}
::v-deep(#vs1__listbox) {
  max-height: 150px;
}
::v-deep(#vs2__listbox) {
  max-height: 150px;
}
::v-deep(#vs3__listbox) {
  max-height: 150px;
}
::v-deep(.vs__open-indicator:hover) {
  cursor: pointer;
}
::v-deep(.vs__dropdown-menu) {
  min-width: 100%;
  top: calc(100% - 3px);
  border-radius: 0 0 25px 25px;
}
::v-deep(.vs__selected) {
  margin: 9px 2px;
}
::v-deep(#vs1__listbox::-webkit-scrollbar) {
  width: 3px;
  height: 24px;
}
::v-deep(#vs1__listbox::-webkit-scrollbar-thumb) {
  background: #cdd5da;
  border-radius: 100px;
  margin-right: 2px;
}
::v-deep(#vs2__listbox::-webkit-scrollbar) {
  width: 4px;
  height: 24px;
}
::v-deep(#vs2__listbox::-webkit-scrollbar-thumb) {
  background: #cdd5da;
  border-radius: 100px;
}
::v-deep(#vs3__listbox::-webkit-scrollbar) {
  width: 4px;
  height: 24px;
}
::v-deep(#vs3__listbox::-webkit-scrollbar-thumb) {
  background: #cdd5da;
  border-radius: 100px;
}
</style>