<template>
  <div class="audio-problem">
    <h1 class="audio-problem__title">Обратитесь к администратору</h1>
    <p class="audio-problem__text">
      Главное условие при выполнении любого теста при приеме на работу — не
      пугаться и быть спокойным. Не следует долго задерживаться на вопросе,
      ответ на который вам неизвестен. К нему можно будет вернуться позднее.
      Проверяйте ответы, учитывайте, что вероятность замены неверного ответа на
      верный всегда выше вероятности обратной замены.
    </p>
    <h2 class="audio-problem__title-sound">Проверить звук</h2>
    <audio id="player" class="audio" controls src="../../assets/audio/test-new.mp3"></audio>
    <Audio
      onclick="document.getElementById('player').play()"
      @click="checkSound()"
      class="audio-problem__audio-svg"
    />
  </div>
</template>

<script>
import Audio from "../../assets/images/audio/audio.svg";

export default {
  name: "AudioProblem",
  components: {
    Audio,
  },
};
</script>

<style lang="scss" scoped>
.audio-problem {

  min-height: calc(95vh - 53px);
  &__title {
    @include h1;
    padding: 80px 0 40px 0;
  }

  &__text {
    padding: 0 10% 40px 10%;
  }

  &__title-sound {
    padding-bottom: 20px;
  }

  &__audio-svg {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>