import axios from 'axios';
import { apiPublic, api } from './index';
// eslint-disable-next-line import/no-cycle
import { onResponseInterceptor, onResponseErrorInterceptor } from './interceptors';
// eslint-disable-next-line import/no-cycle
import { getAccessToken } from '../helpers/auth';
import qs from 'qs';

export const logInUser = (data, lang) => apiPublic.request({
  method: 'POST',
  url: `/testLogin?${qs.stringify({ lang })}`,
  data
});
