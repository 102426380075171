<template>
  <Layout>
  <div class="login">
    <Auth />
  </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout.vue'
import Auth from '../organisms/Auth.vue'

export default {
  name: 'Login',
  components: {
    Layout,
    Auth
  }
}
</script>

<style scss="lang" scoped>

</style>