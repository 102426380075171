<template>
  <div id="app">
    <router-view />
    <div class="popup">
      <div class="popup-content">
        <p>{{ $t('popup.only_on_big_screens') }}</p>
        <p>{{ $t('popup.please_visit_computer') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getStoredUserInfo } from './helpers/auth';

export default {
  created() {
    const userInfo = getStoredUserInfo() || null;
    this.$store.commit('authStore/setUserInfo', userInfo);
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === '/' && from.path === '/refresh') {
      next(false);
      window.location.href = '/';
    } else {
      next();
    }
  }
};
</script>

<style lang="scss">
.popup {
  display: none;
}
body {
  background: #ffffff;
  height: 100%;
  font-size: $baseFontsSize;
}
#app {
  font-family: Open Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $mainTextColor;
}
.custom-checkbox,
.checkbox-img {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox + label,
.checkbox-img + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  margin-right: 15px;
  background: #dedede;
}
.checkbox-img + label::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
  z-index: 1;
  background: $mainTestColor;
}
.custom-checkbox:checked + label::before {
  background-color: #a6aeb2;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 2px;
}
.checkbox-img:checked + label::before {
  background-color: #cdd5da;
}
.audio {
  display: none;
}
@media screen and (max-width: 999px) {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    height: 90vh;
    padding: 20px;
    border-radius: 10px;
    text-align: center;

    p {
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      padding-bottom: 20px;
    }
  }
}
</style>
